var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-404" }, [
    _c(
      "h1",
      [
        _c("v-icon", { staticClass: "icon-404", attrs: { dark: "" } }, [
          _vm._v(" mdi-file-outline ")
        ]),
        _c("br"),
        _c("span", [_vm._v("404")]),
        _c("br"),
        _vm._v(" Page Not Found ")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }